import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      anna: file(relativePath: { eq: "mitarbeiter/anna.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Motivationsgruppe" />
      <Header siteTitle="Beratung" subTitle="Motivationsgruppe" />
      <TwoAndOne>
        <div className="content">
          <p>
            Viele Glücksspielsüchtige haben oftmals schon etliche Versuche
            unternommen, um mit dem Glücksspielen aufzuhören. Die meisten dieser
            Versuche haben im Verborgenen stattgefunden. „Sich zusammenreißen“,
            „einfach nicht mehr hingehen“ oder die „Geldeinteilung“ über Freunde
            und Familien sind drei der häufigsten Strategien, um eigenständig
            mit dem Glücksspielen aufzuhören. Begleitet werden diese Versuche
            zumeist von der bitteren Erfahrung, dass es ihnen nicht gelingt,
            diese Vorsätze durchzuhalten.
          </p>
          <p>
            Wenn Ihnen das Glücksspielen Probleme macht, Sie sich abhängig oder
            von Abhängigkeit bedroht fühlen und etwas an Ihrer Situation
            verändern wollen, sind wir Ihre Ansprechpartner. Unsere
            Suchttherapeuten bieten Ihnen nach einem Erstgespräch die Teilnahme
            an einer therapeutisch angeleiteten Motivationsgruppe für
            Glücksspielende an. Diese Motivationsgruppe findet einmal in der
            Woche statt und dauert 90 Minuten. In der geschützten Atmosphäre
            dieser Gesprächsgruppe erfahren Sie Unterstützung durch andere,
            ebenso betroffene Teilnehmerinnen und Teilnehmer und erarbeiten
            zusammen mit den Therapeuten erste wichtige Schritte zur
            Veränderung. Eine vorherige Spielfreiheit ist keine
            Aufnahmevoraussetzung.
          </p>
          <p>
            Die Motivationsgruppe dient der Entwicklung einer Krankheitseinsicht
            sowie der Förderung der Behandlungsbereitschaft. Die
            Gruppengespräche mit gleichermaßen Betroffenen ermöglichen einen
            Ausweg aus der eigenen Isolation, die durch Verschweigen und
            Verheimlichen der Problematik sowie dem Unverständnis der
            „Außenwelt“ oft über Jahre gewachsen ist. Zusammen mit den
            Therapeuten werden hilfreiche Handlungsschritte erarbeitet, die ein
            spielfreies Leben unterstützen. Im Rahmen der Motivationsgruppe
            sowie in begleitenden Einzelgesprächen wird die weitere
            Behandlungsform zur therapeutischen Bearbeitung der Abhängigkeit
            geklärt.
          </p>
          <div className="notification is-primary">
            Die Motivationsgruppe kann dabei zur Vorbereitung und Beantragung
            einer stationären oder ambulanten Therapie dienen.
          </div>
        </div>
        <div className="content">
          <h4 className="title">Ansprechpartnerin</h4>
          <div className="columns">
            <div className="column is-one-third">
              <Img fluid={data.anna.childImageSharp.fluid} />
            </div>
            <div className="column ">
              <h5 className="title">Anna Müller-Bödige</h5>
              <h6 className="subtitle">
                Dipl.-Sozialpädagogin, Sozial- und Suchttherapeutin (VT)
              </h6>
              <p className="is-size-7">
                <a href="mailto:mueller.boedige@ak-spielsucht.de">
                  <span class="icon">
                    <i class="fas fa-envelope"></i>
                  </span>
                  mueller.boedige@ak-spielsucht.de
                </a>
                <br />
                <span class="icon">
                  <i class="fas fa-phone"></i>
                </span>
                02303 89669
              </p>
            </div>
          </div>
          <h4 className="title">Weitere Beratungsangebote</h4>
          <ul>
            <li>
              <Link to="/beratung/rehabilitation-und-nachsorge">
                Rehabilitation & Nachsorge
              </Link>
            </li>
          </ul>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
